import React from "react";
import { graphql } from "gatsby";
import { MainContainer } from "../../ui/components/MainContainer/MainContainer";

export default ({ data, pageContext }) => {
  const totalCount = data.allContentfulKnowledgeHubArticle.totalCount;
  const articles = data.allContentfulKnowledgeHubArticle.edges.map(
    (article) => article.node
  );
  const variables = data.allContentfulVariables.nodes;
  const categoriesMap = data.categoriesMap.group.map(
    ({ nodes }) => nodes[0].category.contentful_id
  );
  const featuredArticle = data.contentfulPage.blocks.featuredArticle;
  const categories = data.contentfulPage.blocks.categories;

  return (
    <MainContainer
      articles={articles}
      totalCount={totalCount}
      variables={variables}
      featuredArticle={featuredArticle}
      categories={categories}
      categoriesMap={categoriesMap}
    ></MainContainer>
  );
};

export const pageQuery = graphql`
  query KHMainPageQuery(
    $contentful_id: String!
    $locale: String
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulVariables(
      filter: {
        contentfulid: {
          in: ["customerCount", "operating-countries", "booking-experience"]
        }
        node_locale: { eq: $locale }
      }
      sort: { fields: id }
    ) {
      nodes {
        label
        unit
        value
        contentfulid
      }
    }
    contentfulPage(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentful_id }
    ) {
      contentful_id
      node_locale
      seoMeta {
        slug
        title
        description
      }
      blocks {
        ... on ContentfulKnowledgeHubPageAssembly {
          headline
          categories {
            title
            contentful_id
            seoMeta {
              slug
              title
            }
            knowledgehubarticle {
              id
            }
          }
          featuredArticle {
            title
            seoMeta: localContent {
              slug
              title
            }
            localContent {
              title
              slug
              excerpt {
                raw
              }
            }
            category {
              seoMeta {
                slug
                title
              }
            }
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 600
                    width: 840
                    placeholder: BLURRED
                    transformOptions: {
                      fit: COVER
                      cropFocus: ATTENTION
                      trim: 1
                    }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
    categoriesMap: allContentfulKnowledgeHubArticle(
      sort: { fields: [publishedDate, createdAt], order: DESC }
      filter: {
        node_locale: { eq: $locale }
        localContent: { slug: { ne: null } }
      }
    ) {
      totalCount
      group(field: category___contentful_id, limit: 1) {
        nodes {
          category {
            contentful_id
          }
        }
      }
    }

    allContentfulKnowledgeHubArticle(
      limit: 6
      filter: {
        category: { featureInNews: { ne: true } }
        node_locale: { eq: $locale }
        localContent: { slug: { ne: null } }
      }
      sort: { fields: [publishedDate, createdAt], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...KnowledgeHubArticleCard
        }
      }
    }
  }
`;
